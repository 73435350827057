<template>
        <base-page>
        <titlu-pagina Titlu="Car count" :AdaugaVisible="false" />
        <el-card style='margin:5px 0px 5px 0px'>
            <div slot="header" class="clearfix">
                <strong> Filtre </strong>
            </div>
            <div class="filtre">
                <el-form @submit.prevent.native='refresh_info()'>
                    <el-row :gutter="20">      
                        <el-col :md='24'>
                            <el-form-item label='Date range' >
                                <el-date-picker
                                    v-model="Filters.DateRange"
                                    type="daterange"
                                    align="right"
                                    format='dd MMM yyyy'
                                    unlink-panels
                                    range-separator="-"
                                    start-placeholder="Start date"
                                    end-placeholder="End date"
                                    :picker-options="pickerOptions">
                                    </el-date-picker>
                                    <el-button style='margin-left:10px' type='primary' @click='refresh_info()'>Apply</el-button>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
                </div>
        </el-card>

        <el-table :data="Results" show-summary :summary-method="getSummaries"  highlight-current-row>
            
             <el-table-column type="expand">
                <template slot-scope="props">
                    <div align='center'>
                        <el-table :data="props.row.ListaMasini">
                            <el-table-column type="index" width="50"></el-table-column>
                            <el-table-column prop="Masina" label="Masina"></el-table-column>
                            
                            <el-table-column prop="Marca" label="Marca"></el-table-column>
                            <el-table-column prop="NumeClient" label="Client"></el-table-column>

                            <el-table-column prop="Deviz" label="Deviz">
                                <template slot-scope="props2">
                                    <table class='table full-width'>
                                        <tr>
                                            <td align='right'>
                                                Cantitate
                                            </td>
                                            <td>
                                                Produs
                                            </td>
                                        </tr>
                                        <tr v-for="p in props2.row.Produse" :key="'produs'+p.Id">
                                            <td align='right'>
                                                {{p.Cantitate}}
                                            </td>
                                            <td>
                                                {{p.NumeProdus}}
                                            </td>
                                        </tr>
                                    </table>
                                    
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </template>
            </el-table-column>
            <el-table-column prop='Data' label='Data'>
                <template slot-scope="props">
                    {{format_data(props.row.Data)}}
                </template>
            </el-table-column>
            <el-table-column prop='Total' label='Total Masini' align='right' :formatter='qty_formatter'></el-table-column>
        </el-table>

</base-page>
</template>

<script>
import settings from "@/backend/LocalSettings";
import BasePage from "@/pages/BasePage";
import TitluPagina from '@/widgets/TitluPagina';
import moment from 'moment';

export default {
    name: "car-count",
    extends: BasePage,
    components: {
        'base-page': BasePage,
        'titlu-pagina': TitluPagina
    },
    data () {
        return {
            Results:  [],
            base_url: '',
            Info:    { },

            Filters: {  DateRange: [ moment().toDate(), moment().toDate()] },
            

            pickerOptions: {
                shortcuts: [{
                    text: 'Last week',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: 'Last month',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: 'Last 3 months',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                        picker.$emit('pick', [start, end]);
                    }
                }]
            },
        }
    },
    methods: {

        format_data(d){
            return moment(d, "YYYY-MM-DD").format("DD MMM YYYY");
        },

        async get_info() {
            var response = await this.post("serii/get_info" );
            this.refresh_info();
        },

        qty_formatter(row, column, cellValue, index){
            return this.$options.filters.format_quantity(cellValue) ;
        },

        async refresh_info(){
            
            var dataStart = moment( this.Filters.DateRange[0] ).format("YYYY-MM-DD");
            var dataEnd   = moment( this.Filters.DateRange[1] ).format("YYYY-MM-DD");

            var response        = await this.post("reports_car_count", { DataStart: dataStart, DataEnd: dataEnd } );
            this.Results        = response.Results;
            //
            this.select_menu_item('car-count');
        },

        getSummaries(param) {
            const { columns, data } = param;
            const sums = [];
            columns.forEach((column, index) => {
            if (index === 0) {
                sums[index] = 'Total';
                return;
            }
            const values = data.map(item => Number(item[column.property]));
            if (!values.every(value => isNaN(value))) {
                sums[index] =  values.reduce((prev, curr) => {
                    const value = Number(curr);
                    if (!isNaN(value)) {
                        return prev + curr;
                    } else {
                        return prev;
                    }
                }, 0);
                sums[index] = this.$options.filters.format_quantity(sums[index]) ;
            } else {
                sums[index] = 'N/A';
            }
            });

            return sums;
        },
        
        reset() {
            this.Filters = {};
            this.refresh_info();
        },

    },
    mounted(){
        this.base_url = settings.BASE_URL;
        this.get_info();
    }
};
</script>

<style lang="less" scoped>

    .top50{
        margin-top: 20px;
    }

    .filtre{
        .el-input-number
        {
            width: 100% !important;
        }
    }

</style>
